/* --- Pagination --- */
.pagination {
  @extend %padding-nav;
  display: inline-block;
  text-align: center;
  //margin-bottom: 2em;
  width: 100%;
  clear: both;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .page_number {
    display: inline-block;
    vertical-align: sub;

    @media (max-width: $x-sm-break) {
      display: none;
    }
  }

  .button {
    margin: 0;
    display: inline-block;

    .fa {
      &.fa-chevron-right, &.fa-chevron-left {
        font-size: initial;
      }
    }

    i .fa-chevron-left .fa-chevron-right {
      vertical-align: inherit;
    }
  }

  .previous {
    float: left;
  }

  .next {
    float: right;
  }
}
